import React from "react";
import "bootstrap-icons/font/bootstrap-icons.css";

function Service(props) {
  return (
    <div className="col-lg-4 col-md-12 text-center">
      <div className="mt-5">
        <div className="mb-2">
          <i className={`bi ${props.iconName} fs-1 text-primary`}></i>
        </div>
        <h3 className="h4 mb-2">{props.title}</h3>
        <p className="text-muted mb-2">{props.text}</p>
        <p>
          <a className="link-opacity-50-hover" href={props.path}>
            انقر هنا
          </a>
        </p>
      </div>
    </div>
  );
}

export default Service;
