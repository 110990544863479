import React from "react";
import "../../assets/css/masthead.css";
import Masthead from "../../components/Masthead";
import Services from "../../components/Services";
import Punycode from "../../components/Punycode";
import Counter from "../../components/Counter";

function Home() {
  return (
    <>
      <Masthead />
      <Services />
      <Punycode />
      <Counter />
    </>
  );
}

export default Home;
