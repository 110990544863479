import React, { useState } from "react";
import "bootstrap/dist/js/bootstrap.min.js";
import "../../assets/css/menu.css";
import { useLocation } from "react-router-dom";

const Menu = () => {
  const [shrinkNav, setShrinkNav] = useState(false);
  const navBarShrink = () => {
    if (window.scrollY >= 80) {
      setShrinkNav(true);
    } else {
      setShrinkNav(false);
    }
  };
  window.addEventListener("scroll", navBarShrink);

  //assigning location variable
  const location = useLocation();

  //destructuring pathname from location
  const { pathname } = location;

  //Javascript split method to get the name of the path in array
  const splitLocation = pathname.split("/");

  return (
    <nav
      className={
        shrinkNav
          ? "navbar navbar-expand-lg navbar-light fixed-top py-3 navbar-shrink"
          : "navbar navbar-expand-lg navbar-light fixed-top py-3"
      }
      id="mainNav"
    >
      <div className="container px-4 px-lg-5">
        <a className="navbar-brand" href="/">
          <img src={require("../../assets/img/logo.png")} alt="DZ NIC" />
        </a>

        <button
          className="navbar-toggler navbar-toggler-end"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarResponsive"
          aria-controls="navbarResponsive"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarResponsive">
          <ul className="navbar-nav ms-auto my-2 my-lg-0">
            <li className="nav-item">
              <a
                className={
                  splitLocation[1] === "about" ? "nav-link active" : "nav-link"
                }
                href="/about"
              >
                معلومات عنا
              </a>
            </li>
            <li className="nav-item">
              <a
                className={
                  splitLocation[1] === "registrar"
                    ? "nav-link active"
                    : "nav-link"
                }
                href="/registrar"
              >
                المسجلون
              </a>
            </li>
            <li className="nav-item">
              <a
                className={
                  splitLocation[1] === "registration"
                    ? "nav-link active"
                    : "nav-link"
                }
                href="/registration"
              >
                التسجيل
              </a>
            </li>
            <li className="nav-item">
              <a
                className={
                  splitLocation[1] === "rules" ? "nav-link active" : "nav-link"
                }
                href="/namingRules"
              >
                قواعد صياغة أسماء النطاق
              </a>
            </li>
            <li className="nav-item">
              <a
                className={
                  splitLocation[1] === "docs" ? "nav-link active" : "nav-link"
                }
                href="/docs"
              >
                الوثائق
              </a>
            </li>
            <li className="nav-item">
              <a
                className={
                  splitLocation[1] === "contact"
                    ? "nav-link active"
                    : "nav-link"
                }
                href="/contact"
              >
                اتصل بنا
              </a>
            </li>

            <li className="nav-item">
              <a className="nav-link" href={process.env.REACT_APP_NIC_URL}>
                NIC .DZ
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Menu;
