import React from "react";

const Header = ({ children }) => {
  return (
    <header className="head bg-dark">
      <div className="container px-4 px-lg-5 h-100">
        <div className="row gx-4 gx-lg-5 align-items-center">{children}</div>
      </div>
    </header>
  );
};

export default Header;
