import React from "react";
import { AnimatePresence } from "framer-motion";
import { Route, Routes } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Home from "../../pages/Home";
import About from "../../pages/About";
import Search from "../../pages/Search";
import Registrar from "../../pages/Registrar";
import Registration from "../../pages/Registration";
import Contact from "../../pages/Contact";
import Download from "../../pages/Download";
import Rules from "../../pages/Rules";
import Stats from "../../pages/Stats";
import Encoder from "../../pages/Encoder";
import NotFound from "../../pages/NotFound";

const Navigate = () => {
  const location = useLocation();
  return (
    //<AnimatePresence>
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/search" element={<Search />} />
        <Route path="/registrar" element={<Registrar />} />
        <Route path="/registration" element={<Registration />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/docs" element={<Download />} />
        <Route path="/namingRules" element={<Rules />} />
        <Route path="/:count" element={<Stats />} />
        <Route path="/encode" element={<Encoder />} />
        <Route path="/not-found" element={<NotFound />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    //</AnimatePresence>
  );
};

export default Navigate;
