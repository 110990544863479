import React from "react";
import Service from "../Service";

function Services() {
  return (
    <section className="page-section" id="services">
      <div className="container px-4 px-lg-5">
        <h2 className="text-center mt-0">في خدمتكم</h2>
        <hr className="divider" />
        <div className="row gx-3 gx-lg-5">
          <Service
            iconName="bi-people"
            title="لتصبح مسجلا"
            text="انقر على الرابط أدناه لتتعرف على الشروط و المراحل المتبعة كي تصبح مسجلا"
            path="/registrar"
          />
          <Service
            iconName="bi-card-checklist"
            title="قواعد الصياغة"
            text="انقر على الرابط أدناه للتعرف على القواعد المتبعة لصياغة أسماء النطاق بالعربية"
            path="/namingRules"
          />
          <Service
            iconName="bi-folder2-open "
            title="الوثائق"
            text="انقر على الرابط أدناه لتحميل وثائق التسجيل"
            path="/docs"
          />
        </div>
      </div>
    </section>
  );
}

export default Services;
