import Header from "../../components/Header";
import Box from "../../components/Box";
import Form from "react-bootstrap/Form";
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Alert from "react-bootstrap/Alert";
import * as formik from "formik";
import * as yup from "yup";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  validateCaptcha,
} from "react-simple-captcha";
import { motion } from "framer-motion";

const Contact = () => {
  const [error, setError] = useState(null);

  const { Formik } = formik;

  const schema = yup.object().shape({
    contactName: yup.string().required("الرجاء ادخال اسمك الكامل"),
    email: yup
      .string()
      .email()
      .required("ضروري ادخال عنوان البريد الإلكتروني الخاص بك"),
    phoneNumber: yup
      .string()
      .matches(
        /^([0]{1}|\+?[234]{3})([7-9]{1})([0|1]{1})([\d]{1})([\d]{7})$/g,
        "رقم الهاتف غير صحيح"
      ),
    object: yup
      .string()
      .required("الرجاء إدخال موضوع الرسالة")
      .min(4, "الموضوع قصير جدًا، يجب أن يكون 4 أحرف على الأقل"),
    content: yup
      .string()
      .required("أدخل رسالتك هنا...")
      .min(
        15,
        "رسالة البريد الإلكتروني قصيرة جدًا، يجب أن تكون 15 حرفًا على الأقل"
      ),
    captcha: yup.string().required("الرجاء إدخال الكابتشا"),
  });
  const [isLoading, setIsLoading] = useState(false);
  const baseURL = process.env.REACT_APP_API_URL;
  const [data, setData] = useState("");

  const sendEmail = async (values) => {
    setIsLoading(true);
    if (error) setError(null);
    if (data) setData("");
    try {
      const result = await axios.post(`${baseURL}/email`, values);
      if (result.status === 200) {
        setData(result.data);
      } else {
        setError(result.data.detail);
      }
    } catch (error) {
      setError(error.message);
    }
    setIsLoading(false);
  };

  const captchaRef = useRef(null);
  const handleCaptcha = (values) => {
    const captcha = captchaRef.current.value;
    if (validateCaptcha(captcha)) {
      sendEmail(values);
    } else {
      setError("الرجاء إعادة  إدخال رمز كابتشا صالح");
    }
  };
  useEffect(() => {
    loadCaptchaEnginge(6, "#f4623a", "white");
  });

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      exit={{ opacity: 0, y: -20 }}
    >
      <Header>
        <Box
          colSize="col-sm-6"
          iconName="bi-envelope"
          title="البريد الإلكتروني والموقع"
        >
          <i className="bi bi-envelope" aria-hidden="true"></i> dznic@nic.dz
          <br />
          <br />
          <i className="bi bi-globe" aria-hidden="true"></i> www.nic.dz
        </Box>

        <Box colSize="col-sm-6" iconName="bi-phone" title="جهات الاتصال">
          <i className="bi bi-phone" aria-hidden="true"></i> الإدارية: 95 53 25
          23 213+
          <br />
          <br />
          <i className="bi bi-phone" aria-hidden="true"></i> التقنية: 08 54 25
          23 213 +
        </Box>

        <Box colSize="col-sm-12" iconName="bi-geo-alt" title="العنوان">
          <i className="bi bi-geo-alt" aria-hidden="true"></i> 05 شارع الإخوة
          عيسو الثلاث، ص.ب 143، 16030 بن عكنون الجزائر
        </Box>
      </Header>
      <section style={{ padding: "4rem 0" }}>
        <div class="container px-4 px-lg-5">
          <div className="row gx-4 gx-lg-5 justify-content-center">
            <div className="col-lg-8 text-center">
              <h2 className="mt-0">اتصل بنا</h2>
              <hr className="divider" />
            </div>
          </div>
          <div className="row gx-4 gx-lg-5 justify-content-center mb-5">
            <div className="col-lg-6">
              <Formik
                validationSchema={schema}
                onSubmit={(values) => handleCaptcha(values)}
                initialValues={{
                  contactName: "",
                  email: "",
                  phoneNumber: "",
                  object: "",
                  content: "",
                  captcha: "",
                }}
              >
                {({ handleSubmit, handleChange, values, errors }) => (
                  <Form noValidate onSubmit={handleSubmit}>
                    <Form.Group controlId="validationCustom1 ">
                      <FloatingLabel
                        controlId="floatingName"
                        label="الاسم الكامل"
                        className="mb-3"
                      >
                        <Form.Control
                          required
                          name="contactName"
                          type="text"
                          placeholder="Enter your name..."
                          value={values.contactName}
                          onChange={handleChange}
                          isInvalid={!!errors.contactName}
                        />
                        <Form.Control.Feedback type="invalid" tooltip>
                          {errors.contactName}
                        </Form.Control.Feedback>
                      </FloatingLabel>
                    </Form.Group>

                    <Form.Group controlId="validationCustom2">
                      <FloatingLabel
                        controlId="floatingEmail"
                        label="البريد إلكتروني"
                        className="mb-3"
                      >
                        <Form.Control
                          name="email"
                          type="email"
                          placeholder="name@example.com"
                          required
                          value={values.email}
                          onChange={handleChange}
                          isInvalid={!!errors.email}
                        />
                        <Form.Control.Feedback type="invalid" tooltip>
                          {errors.email}
                        </Form.Control.Feedback>
                      </FloatingLabel>
                    </Form.Group>

                    <Form.Group controlId="validationCustom3">
                      <FloatingLabel
                        controlId="floatingPhone"
                        label="رقم الهاتف"
                        className="mb-3"
                      >
                        <Form.Control
                          name="phoneNumber"
                          type="tel"
                          placeholder="(123) 456-7890"
                          required
                          value={values.phoneNumber}
                          onChange={handleChange}
                          isInvalid={!!errors.phoneNumber}
                        />

                        <Form.Control.Feedback type="invalid" tooltip>
                          {errors.phoneNumber}
                        </Form.Control.Feedback>
                      </FloatingLabel>
                    </Form.Group>
                    <Form.Group controlId="validationCustom4">
                      <FloatingLabel
                        controlId="floatingObject"
                        label="الموضوع"
                        className="mb-3"
                      >
                        <Form.Control
                          name="object"
                          type="text"
                          placeholder="Enter your object here ..."
                          required
                          value={values.object}
                          onChange={handleChange}
                          isInvalid={!!errors.object}
                        />
                        <Form.Control.Feedback type="invalid" tooltip>
                          {errors.object}
                        </Form.Control.Feedback>
                      </FloatingLabel>
                    </Form.Group>
                    <Form.Group controlId="validationCustom5">
                      <FloatingLabel
                        controlId="floatingMsg"
                        label="الرسالة"
                        className="mb-3"
                      >
                        <Form.Control
                          name="content"
                          type="text"
                          placeholder="Enter your message here..."
                          style={{ height: "10rem" }}
                          as="textarea"
                          rows={4}
                          required
                          value={values.content}
                          onChange={handleChange}
                          isInvalid={!!errors.content}
                        ></Form.Control>

                        <Form.Control.Feedback type="invalid" tooltip>
                          {errors.content}
                        </Form.Control.Feedback>
                      </FloatingLabel>
                    </Form.Group>

                    <LoadCanvasTemplate
                      reloadText="إعادة تعيين الكابتشا"
                      reloadColor="#f4623a"
                    />

                    <Form.Group controlId="validationCustom6 ">
                      <FloatingLabel
                        controlId="floatingCaptcha"
                        label="الكابتشا"
                        className="mb-3 mt-3"
                      >
                        <Form.Control
                          required
                          name="captcha"
                          type="text"
                          ref={captchaRef}
                          placeholder="Enter your captcha..."
                          value={values.captcha}
                          onChange={handleChange}
                          isInvalid={!!errors.captcha}
                        />
                        <Form.Control.Feedback type="invalid" tooltip>
                          {errors.captcha}
                        </Form.Control.Feedback>
                      </FloatingLabel>
                    </Form.Group>

                    {isLoading && (
                      <div className="col-sm-12  mb-3 text-center">
                        <img
                          src={require("../../assets/img/loading.gif")}
                          alt="Search.loading"
                          className="center-block"
                        />
                      </div>
                    )}

                    {!isLoading && data && <Alert variant="info">{data}</Alert>}

                    {!isLoading && error && (
                      <div
                        class="alert alert-danger d-flex align-items-center"
                        role="alert"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="currentColor"
                          class="bi bi-exclamation-triangle-fill flex-shrink-0 me-2"
                          viewBox="0 0 16 16"
                          role="img"
                          aria-label="Warning:"
                        >
                          <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                        </svg>
                        <div>{error}</div>
                      </div>
                    )}

                    <div className="d-grid">
                      <button
                        className="btn btn-primary btn-xl"
                        id="submitButton"
                        type="submit"
                      >
                        إرسال
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </section>
    </motion.div>
  );
};

export default Contact;
