import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import "../../assets/css/registrar.css";
import Table from "../../components/Table";
import formPdf from "../../assets/docs/RegistrarForm.pdf";
import { motion } from "framer-motion";

const Registrar = () => {
  const [activeSection, setActiveSection] = useState(null);

  const handleScroll = () => {
    const scrollPosition = window.scrollY;

    const sections = document.querySelectorAll(".section");
    let active = null;

    sections.forEach((section) => {
      const sectionTop = section.offsetTop - 300;
      const sectionBottom = sectionTop + section.clientHeight;

      if (scrollPosition >= sectionTop && scrollPosition < sectionBottom) {
        active = section.id;
      }
    });

    setActiveSection(active);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const titles = ["الترتيب", "اسم المسجل"];

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      exit={{ opacity: 0, y: -20 }}
    >
      <Header>
        <div className="col-lg-8 text-center" style={{ margin: "auto" }}>
          <h2 className="text-light mt-0">المسجلون</h2>
          <hr className="divider divider-orange" />
        </div>
      </Header>
      <section style={{ padding: "4rem 0" }}>
        <div className="container px-4 px-lg-5">
          <div className="row">
            <div className="col-md-3 mb-5" id="rightCol">
              <ul className="nav nav-stacked sticky-top" id="sidebar">
                <li className="w-100">
                  <a
                    href="#sec1"
                    className={activeSection === "sec1" ? "selected" : ""}
                  >
                    كيف تصبح مسجلا؟
                  </a>
                </li>
                <li className="w-100">
                  <a
                    href="#sec2"
                    className={activeSection === "sec2" ? "selected" : ""}
                  >
                    قائمة المسجلين
                  </a>
                </li>
                <li className="w-100">
                  <a
                    href="#sec3"
                    className={activeSection === "sec3" ? "selected" : ""}
                  >
                    إستمارة التسجيل
                  </a>
                </li>
              </ul>
            </div>

            <div className="col-md-9" id="leftCol">
              <div className="row">
                <div className="col-sm-12 mb-5 section" id="sec1">
                  <h2 className="mb-5">كيف تصبح مسجلا؟</h2>
                  <div className="text-muted">
                    <p className="text-justify">
                      الشروط لتصبح مسجلا معتمدا من قبل{" "}
                      <strong className="text-primary">
                        مركزأسماءالنطاقات.الجزائر
                      </strong>{" "}
                      هي كالتالي:
                    </p>
                    <ol type="1">
                      <li className="py-2">
                        الحصول على البنية التحتية و المهارات اللازمة لإدارة موزع
                        خدمات إدارة أسماء النطاقات
                      </li>
                      <li className="py-2 text-end">
                        البنية التحتية لموزع الخدمات الرئيسي لإدارة أسماء
                        النطاقات يجب أن تتواجد بالجزائر
                      </li>
                      <li className="py-2">
                        إتباع كل قواعد و إجراءات مركز أسماء النطاقات .الجزائر
                        المسماة في ميثاق .الجزائر
                      </li>
                      <li className="py-2 text-end">
                        التسجيل في السجل .الجزائر ( ملئ استمارة تسجيل المسجل
                        التي يقدمها مركز أسماء النطاقات.الجزائر){" "}
                      </li>
                      <li className="py-2">
                        {" "}
                        الإجتماع بين ممثلي مركز أسماء النطاقات .الجزائر و المسجل
                        ( مناقشة الجوانب : المكان، طبيعة العمل التجاري، الطاقة
                        الاستيعابية للشبكة، المعدات، التقنيات الإدارة و
                        التسويق....)
                      </li>
                      <li className="py-2 text-end">
                        تجديد الترخيص سنويا للحفاظ على الوضعية كمسجل مع المسجل
                        .الجزائر
                      </li>
                    </ol>
                  </div>
                </div>
                <hr className="separation mb-5" />
                <div className="col-sm-12 mb-5 section" id="sec2">
                  <h2 className="mb-5">قائمة المسجلين</h2>

                  <Table titles={titles} />
                </div>
                <hr className="separation mb-5" />
                <div className="col-sm-12 section" id="sec3">
                  <h2 className="mb-5">إستمارة التسجيل</h2>
                  <p className="text-secondary">
                    لتحميل استمارة التسجيل اضغط على الرابط أدناه
                  </p>
                  <div className="text-center">
                    <a
                      id="download"
                      href={formPdf}
                      download
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span>تحميل</span>
                      <span>PDF</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </motion.div>
  );
};

export default Registrar;
