import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";

const Table = (props) => {
  const [registrars, setRegistrars] = useState([]);
  const baseURL = process.env.REACT_APP_API_URL;
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    loadRegistrars();
  }, []);
  const loadRegistrars = async () => {
    setIsLoading(true);
    try {
      const result = await axios.get(`${baseURL}/registrars`, {
        validateStatus: () => {
          return true;
        },
      });
      if (result.status === 302) {
        setRegistrars(result.data);
      } else {
        setError(result.data.detail);
      }
    } catch (error) {
      setError(error.message);
      console.log(error.message);
    }
    setIsLoading(false);
  };
  return (
    <div class="table-responsive custom-table-responsive">
      {!isLoading && error && (
        <div class="alert alert-danger d-flex align-items-center" role="alert">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="currentColor"
            class="bi bi-exclamation-triangle-fill flex-shrink-0 me-2"
            viewBox="0 0 16 16"
            role="img"
            aria-label="Warning:"
          >
            <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
          </svg>
          <div>{error}</div>
        </div>
      )}

      {isLoading && (
        <div className="text-center" style={{ width: "100%" }}>
          <img
            src={require("../../assets/img/loading.gif")}
            alt="تحميل..."
            className="center-block"
          />
        </div>
      )}
      <table class="table custom-table">
        <thead>
          <tr>
            {props.titles.map((title, index) => (
              <th scope="col" key={index}>
                {title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {!isLoading &&
            registrars.map((registrar, index) => (
              <>
                <tr>
                  <td>{index + 1}</td>
                  <td>{registrar.idRegistrar}</td>
                </tr>
                <tr class="spacer">
                  <td colspan="100"></td>
                </tr>
              </>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
