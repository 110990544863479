import React from "react";

function Box(props) {
  return (
    <div className={`col-xs-12 ${props.colSize} col-lg-4`}>
      <div className="box">
        <div className="icon">
          <div className="image">
            <i className={`bi ${props.iconName}`} aria-hidden="true"></i>
          </div>
          <div className="info">
            <h3 className="title text-uppercase">{props.title}</h3>
            <p>{props.children}</p>
          </div>
        </div>
        <div className="space"></div>
      </div>
    </div>
  );
}

export default Box;
