import React from "react";

const DomainCard = ({ item }) => {
  return (
    <div class="card col-sm-12 mb-3" key={item.id}>
      <div class="card-body">
        <h5 class="card-title text-primary position-relative">
          {item.domainName}
          <span class="badge bg-primary p-2 ml-2">{item.registrar}</span>
        </h5>

        <h6 class="card-subtitle mb-2 text-muted">{item.orgName}</h6>
        <p class="card-text mt-3">
          <ul className="list-inline">
            <li>
              <i className="bi bi-geo-alt card-icon text-primary"></i>
              <span className="text-muted card-label">عنوان الهيئة:</span>
              <span>{item.addressOrg}</span>
            </li>
          </ul>
          <h6 className="card-second-title text-primary">الإتصال الإداري</h6>

          <ul className="list-inline">
            <li>
              <i className="bi bi-building card-icon text-primary"></i>
              <span className="text-muted card-label">الهيئة:</span>
              <span>{item.orgNameAdm}</span>
            </li>
            <li>
              <i className="bi bi-geo-alt card-icon text-primary"></i>
              <span className="text-muted card-label">العنوان:</span>
              <span>{item.addressAdm}</span>
            </li>
            <li>
              <i className="bi bi-person card-icon text-primary"></i>
              <span className="text-muted card-label">الإتصال:</span>
              <span>{item.contactAdm}</span>
            </li>
            <li>
              <i className="bi bi-envelope card-icon text-primary"></i>
              <span className="text-muted card-label">البريد إلكتروني:</span>
              <span>{item.emailAdm}</span>
            </li>
            <li>
              <i className="bi bi-telephone card-icon text-primary"></i>
              <span className="text-muted card-label">الهاتف:</span>
              <span>{item.phoneAdm}</span>
            </li>
            <li>
              <i className="bi bi-telephone card-icon text-primary"></i>
              <span className="text-muted card-label">الفاكس:</span>
              <span>{item.faxAdm}</span>
            </li>
          </ul>
          <h6 className="card-second-title text-primary">الإتصال التقني</h6>

          <ul className="list-inline">
            <li>
              <i className="bi bi-building card-icon text-primary"></i>
              <span className="text-muted card-label">الهيئة:</span>
              <span>{item.orgNameTech}</span>
            </li>
            <li>
              <i className="bi bi-geo-alt card-icon text-primary"></i>
              <span className="text-muted card-label">العنوان:</span>
              <span>{item.addressTech}</span>
            </li>
            <li>
              <i className="bi bi-person card-icon text-primary"></i>
              <span className="text-muted card-label">الإتصال:</span>
              <span>{item.contactTech}</span>
            </li>
            <li>
              <i className="bi bi-envelope card-icon text-primary"></i>
              <span className="text-muted card-label">البريد إلكتروني:</span>
              <span>{item.emailTech}</span>
            </li>
            <li>
              <i className="bi bi-telephone card-icon text-primary"></i>
              <span className="text-muted card-label">الهاتف:</span>
              <span>{item.phoneTech}</span>
            </li>
            <li>
              <i className="bi bi-telephone card-icon text-primary"></i>
              <span className="text-muted card-label">الفاكس:</span>
              <span>{item.faxTech}</span>
            </li>
          </ul>
        </p>
      </div>
      <div className="card-footer">
        <span className="text-muted card-label">تاريخ الإنشاء:</span>
        {item.creationDate}
      </div>
    </div>
  );
};

export default DomainCard;
