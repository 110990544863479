import React from "react";
import Header from "../../components/Header";
import Section from "../../components/Section";
import Form from "react-bootstrap/Form";
import axios from "axios";
import { useEffect, useState } from "react";
import { useSearchParams, useLocation } from "react-router-dom";
import DomainCard from "../../components/DomainCard";
import { motion } from "framer-motion";

const Search = () => {
  const location = useLocation();
  const baseURL = process.env.REACT_APP_API_URL + "/arabic/domains";
  const [searchParams, setSearchParams] = useSearchParams();
  const param = searchParams.get("name");
  const [name, setName] = useState(param ? param : "");
  const [domain, setDomain] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      event.preventDefault();
      setValidated(true);
      setSearchParams({ name: name });
      loadDomains();
    }
  };

  useEffect(() => {
    loadDomains();
  }, []);
  const loadDomains = async () => {
    setIsLoading(true);
    if (error) setError(null);
    if (domain) setDomain(null);
    try {
      const result = await axios.get(`${baseURL}/${name}`, {
        validateStatus: () => {
          return true;
        },
      });
      if (result.status === 302) {
        setDomain(result.data);
      } else {
        setError(result.data.detail);
      }
    } catch (error) {
      setError(error.message);
    }
    searchParams.delete("name");
    setSearchParams(searchParams);
    setIsLoading(false);
  };
  return (
    <motion.div
      
      key={location.pathname}
    >
      <Header>
        <div className="col-lg-8 text-center" style={{ margin: "auto" }}>
          <h2 className="text-light mt-0">الاستعلام عن أسماء النطاقات</h2>
          <hr className="divider divider-orange" />
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Group controlId="validationCustom">
              <Form.Control
                required
                className="mb-3"
                type="text"
                placeholder="اسم النطاق"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <Form.Control.Feedback type="invalid" className="mb-3">
                الرجاء إدخال اسم النطاق
              </Form.Control.Feedback>
            </Form.Group>
            <button className="btn btn-primary btn-xl btn-orange" type="submit">
              بحث
            </button>
          </Form>
        </div>
      </Header>
      <Section>
        {!isLoading && error && (
          <div
            class="alert alert-danger d-flex align-items-center"
            role="alert"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="currentColor"
              class="bi bi-exclamation-triangle-fill flex-shrink-0 me-2"
              viewBox="0 0 16 16"
              role="img"
              aria-label="Warning:"
            >
              <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
            </svg>
            <div>{error}</div>
          </div>
        )}

        {isLoading && (
          <div className="col-sm-12  text-center">
            <img
              src={require("../../assets/img/loading.gif")}
              alt="تحميل ..."
              className="center-block"
            />
          </div>
        )}
        {!isLoading && domain && <DomainCard item={domain} />}
      </Section>
    </motion.div>
  );
};

export default Search;
