import React from "react";
import Header from "../../components/Header";
import Section from "../../components/Section";
import formPdf from "../../assets/docs/formulaire.pdf";
import chartePdf from "../../assets/docs/charte.pdf";
import { motion } from "framer-motion";

const Download = () => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      exit={{ opacity: 0, y: -20 }}
    >
      <Header>
        <div className="col-lg-8 text-center" style={{ margin: "auto" }}>
          <h2 className="text-light mt-0">تحميل الوثائق</h2>
          <hr className="divider divider-orange" />
        </div>
      </Header>
      <Section>
        <div className="downloadBox col-12 col-md-6 p-0 border-top border-bottom border-start border-end">
          <div className="downloadCard card border-0 bg-transparent">
            <div className="card-body text-center p-5">
              <h4 className="fw-bold text-uppercase mb-4">إستمارة التسجيل</h4>
              <p className="mb-4 text-secondary">
                انقر على الرابط أدناه لتحميل استمارة تسجيل اسم النطاق
              </p>
              <a
                id="download"
                href={formPdf}
                download
                target="_blank"
                rel="noopener noreferrer"
              >
                <span>تحميل</span>
                <span>PDF</span>
              </a>
            </div>
          </div>
        </div>

        <div className="downloadBox col-12 col-md-6 p-0 border-top border-bottom border-start border-end">
          <div className="downloadCard card border-0 bg-transparent">
            <div className="card-body text-center p-5">
              <h4 className="fw-bold text-uppercase mb-4">ميثاق التسمية</h4>
              <p className="mb-4 text-secondary">
                انقر على الرابط أدناه لتحميل ميثاق التسمية
              </p>
              <a
                id="download"
                href={chartePdf}
                download
                target="_blank"
                rel="noopener noreferrer"
              >
                <span>تحميل</span>
                <span>PDF</span>
              </a>
            </div>
          </div>
        </div>
      </Section>
    </motion.div>
  );
};

export default Download;
