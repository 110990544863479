import React from "react";

const Punycode = () => {
  return (
    <section className="page-section bg-orange">
      <div className="container px-4 px-lg-5">
        <div className="row gx-4 gx-lg-5 justify-content-center text-center">
          <div className="col-lg-8 align-self-end">
            <h2 className="text-white mt-0">تحويل النطاقات العربية</h2>
            <hr className="divider divider-light" />
            <p className="text-light">
              لتحويل أسماء النطاقات العربية إلى بونيكود أو العكس انقر على الزر
              أدناه
            </p>
            <a className="btn btn-white btn-xl" href={"/encode"}>
              تحويل
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Punycode;
