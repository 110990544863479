import React from "react";
import ReactDOM from "react-dom/client";
import "./assets/css/index.css";
import "./assets/css/custom.min.rtl.css";
import { BrowserRouter as Router } from "react-router-dom";
import Menu from "./components/Menu";
import Footer from "./components/Footer";
import Navigate from "./components/Navigate";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Router basename="/">
      <Menu />
      <Navigate />
      <Footer />
    </Router>
  </React.StrictMode>
);
