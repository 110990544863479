import React from "react";
import Header from "../../components/Header";
import Section from "../../components/Section";
import { motion } from "framer-motion";

const About = () => {
  return (
    <motion.div
       initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      exit={{ opacity: 0, y: -20 }}
    >
      <Header>
        <div class="col-lg-6 order-lg-2 d-none d-md-block">
          <div class="p-5">
            <img
              class="img-fluid"
              src={require("../../assets/img/whoarewe.png")}
              alt="..."
            />
          </div>
        </div>
        <div class="col-lg-6 order-lg-1">
          <div class="p-5 text-light">
            <h2 class="display-4">من نحن؟</h2>
            <div class="text-justify text-white-75">
              <p>
                مركز أسماء النطاقات
                <strong className="text-primary">. الجزائر</strong> هو عضو مرخص
                من الهيئة العالمية للأسماء و الأرقام للأنترنيت من أجل إدارة
                أسماء النطاقات المخصصة للجزائر .
              </p>
              <p>
                إدارة أسماء النطاقات تحت{" "}
                <strong className="text-primary">. الجزائر</strong> هو النشاط
                الذي شرع في تطبيقه المركز منذ 2012
              </p>
              <p>
                عملية التسجيل في الهيئة العالمية للأسماء و الأرقام للأنترنيت
                انطلقت في 2011
              </p>
              <p>
                أسماء النطاقات{" "}
                <strong className="text-primary">. الجزائر</strong> تمنح ضمن
                ترخيص أسماء النطاقات عبر موزع خدمات إدارة أسماء النطاقات
              </p>
            </div>
          </div>
        </div>
      </Header>
      <Section>
        <div class="col-lg-6 d-none d-md-block">
          <div class="p-5">
            <img
              class="img-fluid"
              src={require("../../assets/img/cerist.jpg")}
              alt="..."
            />
          </div>
        </div>
        <div class="col-lg-6">
          <div class="p-5">
            <h2 class="display-4"> أسماء النطاقات</h2>
            <div class="text-justify text-muted">
              <p>
                يمكن الحصول على إسم نطاق تحت الإمتداد{" "}
                <strong className="text-primary">. الجزائر</strong> لجميع الجهات
                المتواجدة في الجزائر أو لها تمثيل مقبول في الجزائرأوحاملي وثيقة
                تبين حقوق ملكية الاسم .
              </p>
              <p>
                يتم التسجيل الفعلي من خلال كيانات للتسجيل مفوضة من قبل مركز
                أسماء النطاقات ، وتدعى
                <strong className="text-primary"> المسجلون</strong>
              </p>
              <p>
                يقوم المسجل بنقل الطلبات إلى مركز أسماء النطاقات{" "}
                <strong className="text-primary">. الجزائر</strong> و يدير أسماء
                النطاقات لحساب عملائه تسجيل أسماء النطاقات في السجل .الجزائر
                معتمد من قبل مركز أسماء النطاقات دون أي تكلفة للمسجلين لصالح
                مودع الطلب .
              </p>
              <p>
                ينبغي ان تتم إجراءات التسجيل من قبل المسجل عبر الانترنيت من خلال
                الموقع : مسجل.مركزأسماءالنطاقات.الجزائر
              </p>
            </div>
          </div>
        </div>
      </Section>
    </motion.div>
  );
};

export default About;
