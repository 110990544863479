import React from "react";
import Form from "react-bootstrap/Form";
import { useState } from "react";
import { useNavigate, createSearchParams } from "react-router-dom";

const Masthead = () => {
  const [name, setName] = useState("");
  const navigate = useNavigate();

  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    const param = { name: name };
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      setValidated(true);

      navigate({
        pathname: "/search",
        search: `?${createSearchParams(param)}`,
      });
    }
  };

  return (
    <header className="masthead">
      <div className="container px-4 px-lg-5 h-100">
        <div className="row gx-4 gx-lg-5 h-100 align-items-center justify-content-center text-center">
          <div className="col-lg-8 align-self-end">
            <h2 className="text-light font-weight-bold">
              مرحبا بكم في السجل الوطني الجزائري لأسماء النطاقات
            </h2>
            <h1 className="text-light">. الجزائر</h1>
            <hr className="divider" />
          </div>
          <div className="col-lg-8 align-self-baseline">
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Form.Group controlId="validationCustom">
                <Form.Control
                  required
                  className="mb-3"
                  type="text"
                  placeholder="أدخل اسم النطاق"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <Form.Control.Feedback type="invalid">
                  Please type a domain name
                </Form.Control.Feedback>
              </Form.Group>
              <button
                className="btn btn-primary btn-xl btn-orange"
                type="submit"
              >
                بحث
              </button>
            </Form>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Masthead;
