import React from "react";

const Section = ({ children }) => {
  return (
    <section>
      <div class="container p-4 p-lg-5">
        <div class="row gx-5 align-items-center">{children}</div>
      </div>
    </section>
  );
};

export default Section;
