import Header from "../../components/Header";
import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";

const Registration = () => {
  const [activeSection, setActiveSection] = useState(null);

  const handleScroll = () => {
    const scrollPosition = window.scrollY;

    const sections = document.querySelectorAll(".section");
    let active = null;

    sections.forEach((section) => {
      const sectionTop = section.offsetTop - 300;
      const sectionBottom = sectionTop + section.clientHeight;

      if (scrollPosition >= sectionTop && scrollPosition < sectionBottom) {
        active = section.id;
      }
    });

    setActiveSection(active);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      exit={{ opacity: 0, y: -20 }}
    >
      <Header>
        <div className="col-lg-8 text-center" style={{ margin: "auto" }}>
          <h2 className="text-light mt-0">التسجيل</h2>
          <hr className="divider divider-orange" />
        </div>
      </Header>

      <section style={{ padding: "4rem 0" }}>
        <div className="container px-4 px-lg-5">
          <div className="row">
            <div className="col-md-3 mb-5" id="rightCol">
              <ul className="nav nav-stacked sticky-top" id="sidebar">
                <li className="w-100">
                  <a
                    href="#sec1"
                    className={activeSection === "sec1" ? "selected" : ""}
                  >
                    الطلب عبر المسجل
                  </a>
                </li>
                <li className="w-100">
                  <a
                    href="#sec2"
                    className={activeSection === "sec2" ? "selected" : ""}
                  >
                    العرض لمركز أسماء النطاقات.الجزائر
                  </a>
                </li>
                <li className="w-100">
                  <a
                    href="#sec3"
                    className={activeSection === "sec3" ? "selected" : ""}
                  >
                    الدخول في قاعدة أسماء النطاق
                  </a>
                </li>
              </ul>
            </div>

            <div className="col-md-9" id="rightCol">
              <div className="row">
                <div className="col-sm-12 mb-5 section" id="sec1">
                  <h2 className="mb-5">الطلب عبر المسجل :</h2>
                  <div className=" text-justify text-muted">
                    <p>
                      أي طلب للتسجيل يجب أن يمر بتقديم ملف لدى مركز أسماء
                      النطاقات ، يحتوي على الوثائق التالية :
                    </p>
                    <ul>
                      <li className="py-2">
                        طلب تسجيل موقع من المالك النهائي لاسم النطاق
                      </li>
                      <li className="py-2">
                        وثيقة لتبرير اختيار اسم النطاق
                        <ul>
                          <li className="py-2">
                            نسخة من السجل التجاري (كيان تجاري) أو
                          </li>
                          <li className="py-2">
                            نسخة من تسجيل العلامة لدى المعهد الوطني لحقوق
                            الملكية الفكرية أو المنظمة العالمية للملكية الفكري
                          </li>
                          <li className="py-2">
                            نسخة من المرسوم الرسمي لإنشاء (هيئة حكومية،جمعية أو
                            منظمة)
                          </li>
                        </ul>
                      </li>
                      <li className="py-2">
                        في حالة وجود وسيط ، يقدم الطالب وكالة يعين فيها الوسيط
                        المكلف بعملية تسجيل اسم النطاق ،إذا لم يكن الوسيط مدرجًا
                        كجهة اتصال تقنية في نموذج التسجيل
                      </li>
                    </ul>
                  </div>
                </div>
                <hr className="separation mb-5" />

                <div className="col-sm-12 mb-5 section" id="sec2">
                  <h2 className="mb-5">
                    العرض لمركز أسماء النطاقات . الجزائر :
                  </h2>
                  <div className=" text-justify text-muted">
                    <p>
                      التقديم لمركزأسماء النطاقات .الجزائر يكون من طرف المسجل,
                      يتعين على المسجل وضع الملف عبر الأنترنيت :
                    </p>
                    <ul>
                      <li className="py-2">الاستمارة مملوءة و ممضاة</li>
                      <li className="py-2">وثيقة لتبرير اختيار اسم النطاق</li>
                    </ul>
                    <p>
                      يقوم مركز أسماء النطاقات .الجزائر بعد هذه الخطوة بتسجيل
                      المعلومات عن مالك النطاق في قاعدة .الجزائر
                    </p>
                    <p>
                      يقوم مركز أسماء النطاقت .الجزائر بتقديم شهادة تسجيل اسم
                      النطاق تحت الطلب
                    </p>
                    <div class="alert alert-warning">
                      <h4 class="alert-heading">هام !</h4>
                      <p>
                        يقوم مركز أسماء النطاقات.الجزائر بالتحقق من صلاحية
                        الملفات، إذا تم اكتشاف أن بعض شروط التسجيل أو معظمها غير
                        كاملة أو متغيرة في هذه الحالة يلجئ المركز .الجزائر إلى
                        إلغاء التسجيل
                      </p>
                    </div>
                  </div>
                </div>
                <hr className="separation mb-5" />

                <div className="col-sm-12 section" id="sec3">
                  <h2 className="mb-5">الدخول في قاعدة أسماء النطاق :</h2>
                  <div className=" text-justify text-muted mb-5">
                    <p>
                      يقوم مركز أسماء النطاقات .الجزائر بتسجيل أسماء النطاقات في
                      قاعدته الخاصة، التي تعتبر أصلية و تحتوي على جميع أسماء
                      النطاقات . الجزائر ، كما يحتفظ هذا الأخير بحقه في جعل كل
                      المعلومات متاحة للجميع عبر موقع الأنترنيت مركز أسماء
                      النطاقات.الجزائر ، و تسمح هذه الطريقة لمعرفة اسم النطاق
                      واتصالاته الإدارية و التقنية.
                    </p>
                  </div>

                  <div class="alert alert-info">
                    <h5 class="alert-heading">
                      تحديث المعلومات في مركز أسماء النطاقات . الجزائر:
                    </h5>
                    <p>
                      {" "}
                      من الممكن طلب إجراء تعديلات في أي وقت من الأوقات على
                      العناصر الإدارية لأسماء النطاق : تغيير الإتصال التقني و
                      الإداري ، تغيير معلومات .....الخ
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </motion.div>
  );
};

export default Registration;
