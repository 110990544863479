import React, { useState } from "react";
import Header from "../../components/Header";
import Section from "../../components/Section";
import Form from "react-bootstrap/Form";
import * as formik from "formik";
import * as yup from "yup";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import { motion } from "framer-motion";

const Encoder = () => {
  const [encode, setEncode] = useState(false);
  const [text, setText] = useState("");
  const [encodedText, setEncodedText] = useState("");

  const { Formik } = formik;

  const schema = yup.object().shape({
    text: yup.string().min(4, "النص قصير جدًا، يجب أن يكون 4 أحرف على الأقل"),
    punycode: yup.string(),
  });

  const punycode = require("punycode/");

  const convert = (values) => {
    if (encode) {
      setEncodedText(punycode.toASCII(values.text));
      setText(values.text);
    } else {
      setText(punycode.toUnicode(values.punycode));
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      exit={{ opacity: 0, y: -20 }}
    >
      <Header>
        <div className="col-lg-8 text-center" style={{ margin: "auto" }}>
          <h2 className="text-light mt-0">تحويل أسماء النطاقات العربية</h2>
          <hr className="divider divider-orange" />
        </div>
      </Header>
      <Section>
        <Formik
          validationSchema={schema}
          enableReinitialize={true}
          onSubmit={(values) => convert(values)}
          initialValues={{
            text: text,
            punycode: encodedText,
          }}
        >
          {({ handleSubmit, handleChange, values, errors }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Row className="mb-3 align-items-center">
                <Form.Group as={Col} controlId="validationCustom1 ">
                  <Form.Label>اسم النطاق</Form.Label>
                  <Form.Control
                    name="text"
                    type="text"
                    placeholder="أدخل اسم النطاق بالعربية"
                    style={{ height: "10rem" }}
                    as="textarea"
                    rows={4}
                    required
                    value={values.text}
                    onChange={handleChange}
                    isInvalid={!!errors.text}
                  ></Form.Control>
                  <Form.Control.Feedback type="invalid" tooltip>
                    {errors.text}
                  </Form.Control.Feedback>
                </Form.Group>
                <Col md={1} sm="12">
                  <div className="d-none d-md-block text-center">
                    <Button
                      className="mb-2"
                      variant="primary"
                      type="submit"
                      onClick={(e) => setEncode(false)}
                    >
                      <i className={"bi bi-arrow-right"} aria-hidden="true"></i>
                    </Button>
                    <Button
                      variant="primary"
                      type="submit"
                      onClick={(e) => setEncode(true)}
                    >
                      <i className={"bi bi-arrow-left"} aria-hidden="true"></i>
                    </Button>
                  </div>
                  <div className="d-md-none text-center mt-4">
                    <Button
                      className="ml-2"
                      variant="primary"
                      type="submit"
                      onClick={(e) => setEncode(true)}
                    >
                      <i className={"bi bi-arrow-down"} aria-hidden="true"></i>
                    </Button>
                    <Button
                      className="mr-2"
                      variant="primary"
                      type="submit"
                      onClick={(e) => setEncode(false)}
                    >
                      <i className={"bi bi-arrow-up"} aria-hidden="true"></i>
                    </Button>
                  </div>
                </Col>
                <Form.Group as={Col} controlId="validationCustom1 ">
                  <Form.Label>بونيكود</Form.Label>
                  <Form.Control
                    name="punycode"
                    type="text"
                    placeholder="أدخل نص البونيكود"
                    style={{ height: "10rem" }}
                    as="textarea"
                    rows={4}
                    required
                    value={values.punycode}
                    onChange={handleChange}
                    isInvalid={!!errors.punycode}
                  ></Form.Control>
                  <Form.Control.Feedback type="invalid" tooltip>
                    {errors.punycode}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
            </Form>
          )}
        </Formik>
      </Section>
    </motion.div>
  );
};

export default Encoder;
