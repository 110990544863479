import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import { motion } from "framer-motion";

const Rules = (SectionName) => {
  const [activeSection, setActiveSection] = useState(null);

  const handleScroll = () => {
    const scrollPosition = window.scrollY;

    const sections = document.querySelectorAll(".section");
    let active = null;

    sections.forEach((section) => {
      const sectionTop = section.offsetTop - 300;
      const sectionBottom = sectionTop + section.clientHeight;

      if (scrollPosition >= sectionTop && scrollPosition < sectionBottom) {
        active = section.id;
      }
    });

    setActiveSection(active);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      exit={{ opacity: 0, y: -20 }}
    >
      <Header>
        <div className="col-lg-8 text-center" style={{ margin: "auto" }}>
          <h2 className="text-light mt-0">قواعد صياغة أسماء النطاق</h2>
          <hr className="divider divider-orange" />
        </div>
      </Header>
      <section style={{ padding: "4rem 0" }}>
        <div className="container px-4 px-lg-5">
          <div className="row">
            <div className="col-md-3 mb-5" id="rightCol">
              <ul className="nav nav-stacked sticky-top" id="sidebar">
                <li className="w-100">
                  <a
                    href="#sec1"
                    className={activeSection === "sec1" ? "selected" : ""}
                  >
                    الحروف والرموز المسموحة
                  </a>
                </li>
                <li className="w-100">
                  <a
                    href="#sec2"
                    className={activeSection === "sec2" ? "selected" : ""}
                  >
                    قواعد كتابة إسم النطاق
                  </a>
                </li>
              </ul>
            </div>

            <div className="col-md-9" id="leftCol">
              <div className="row">
                <div className="col-sm-12 mb-5 section" id="sec1">
                  <h2 className="mb-5">الحروف والرموز المسموحة:</h2>
                  <div className="text-muted">
                    <p className="text-justify">
                      الحروف والرموز المبينة في الجدول التالي هي فقط المسموح
                      استخدامها في كتابة اسم النطاق :
                    </p>
                    <img
                      src={require("../../assets/img/Arabe_Unicode.jpg")}
                      class="img-thumbnail"
                      alt="Arabe Unicode"
                    />
                  </div>
                </div>

                <div className="col-sm-12 mb-5 section" id="sec2">
                  <h2 className="mb-5">قواعد كتابة إسم النطاق:</h2>
                  <div class="row">
                    <div class="col-md-6 col-sm-12">
                      <div class="service-box mb-3">
                        <div class="service-title">
                          <span>القاعدة 01</span>
                        </div>
                        <div class="service-content">
                          <p>عدم استخدام حركات التشكيل والشدة</p>
                          <h5 className="text-primary">أمثلة :</h5>
                          <table className="table">
                            <tr>
                              <td>سَجّل.الجزائر</td>
                              <td>
                                <i
                                  className="bi bi-x-lg wrong"
                                  aria-hidden="true"
                                ></i>
                              </td>
                            </tr>
                            <tr>
                              <td>سجل.الجزائر</td>
                              <td>
                                <i
                                  className="bi bi-check-lg correct"
                                  aria-hidden="true"
                                ></i>
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6 col-sm-12">
                      <div class="service-box mb-3">
                        <div class="service-title">
                          <span>القاعدة 02</span>
                        </div>
                        <div class="service-content">
                          <p>
                            عدم الخلط بين الحروف العربية والحروف غير العربية
                          </p>
                          <h5 className="text-primary">أمثلة :</h5>
                          <table className="table">
                            <tr>
                              <td>موقع-Wissal.الجزائر</td>
                              <td>
                                <i
                                  className="bi bi-x-lg wrong"
                                  aria-hidden="true"
                                ></i>
                              </td>
                            </tr>
                            <tr>
                              <td>Wissal.الجزائر</td>
                              <td>
                                <i
                                  className="bi bi-x-lg wrong"
                                  aria-hidden="true"
                                ></i>
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6 col-sm-12">
                      <div class="service-box mb-3">
                        <div class="service-title">
                          <span>القاعدة 03</span>
                        </div>
                        <div class="service-content">
                          <p>
                            استخدام الشَرْطة (-) للفصل بين الكلمات في اسم النطاق
                            الواحد خاصة إذا كان دمج الكلمتين يؤدي إلى لبس (أي
                            إذا تشابكت الكلمات مع بعضها البعض)
                          </p>
                          <h5 className="text-primary">أمثلة :</h5>
                          <table className="table">
                            <tr>
                              <td>الجزائر للاتصالات.الجزائر</td>
                              <td>
                                <i
                                  className="bi bi-check-lg correct"
                                  aria-hidden="true"
                                ></i>
                              </td>
                            </tr>
                            <tr>
                              <td>الجزائر-للاتصالات.الجزائر</td>
                              <td>
                                <i
                                  className="bi bi-check-lg correct"
                                  aria-hidden="true"
                                ></i>
                              </td>
                            </tr>
                            <tr>
                              <td>وقت-الجزائر.الجزائر</td>
                              <td>
                                <i
                                  className="bi bi-check-lg correct"
                                  aria-hidden="true"
                                ></i>
                              </td>
                            </tr>
                            <tr>
                              <td>وقتالجزائر.الجزائر</td>
                              <td>
                                <i
                                  className="bi bi-x-lg wrong"
                                  aria-hidden="true"
                                ></i>
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6 col-sm-12">
                      <div class="service-box mb-3">
                        <div class="service-title">
                          <span>القاعدة 04</span>
                        </div>
                        <div class="service-content">
                          <p>
                            عدم وضع الشرطة في بداية أو نهاية أي اسم نطاق، و عدم
                            استخدام شرطتين متتاليتين
                          </p>
                          <h5 className="text-primary">أمثلة :</h5>
                          <table className="table">
                            <tr>
                              <td>-الجزائرللاتصالات.الجزائر</td>
                              <td>
                                <i
                                  className="bi bi-x-lg wrong"
                                  aria-hidden="true"
                                ></i>
                              </td>
                            </tr>
                            <tr>
                              <td>الجزائرللاتصالات-.الجزائر</td>
                              <td>
                                <i
                                  className="bi bi-x-lg wrong"
                                  aria-hidden="true"
                                ></i>
                              </td>
                            </tr>
                            <tr>
                              <td>الجزائر--للاتصالات.الجزائر</td>
                              <td>
                                <i
                                  className="bi bi-x-lg wrong"
                                  aria-hidden="true"
                                ></i>
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12 col-sm-12">
                      <div class="service-box mb-3">
                        <div class="service-title">
                          <span>القاعدة 05</span>
                        </div>
                        <div class="service-content">
                          <p>
                            بعد تسجيل اسم نطاق يحتوي على حرف أو أكثر من الأحرف
                            المتشابهة :
                            <ul>
                              <li>
                                ي ، ى الألف المقصورة والياء في نهاية الكلمة ا ،
                                أ
                              </li>
                              <li>
                                ، إ ، آ الأشكال المختلفة للألف بهمزاتها ه ، ة
                              </li>
                              <li>التاء المربوطة والهاء في نهاية الكلمة</li>
                            </ul>
                            لا يسمح بتسجيل الأسماء الأخرى والتي يمكن إنشاءها من
                            خلال تغيير الحروف الأخرى المشابهة
                          </p>
                          <h5 className="text-primary">أمثلة :</h5>
                          <table className="table">
                            <tr>
                              <td>شبكة-الأخبار.الجزائر</td>
                              <td>
                                <i
                                  className="bi bi-check-lg correct"
                                  aria-hidden="true"
                                ></i>
                              </td>
                            </tr>
                            <tr>
                              <td>شبكه-الاخبار.الجزائر</td>
                              <td>
                                <i
                                  className="bi bi-x-lg wrong"
                                  aria-hidden="true"
                                ></i>
                              </td>
                            </tr>
                            <tr>
                              <td>شبكة-الاخبار.الجزائر</td>
                              <td>
                                <i
                                  className="bi bi-x-lg wrong"
                                  aria-hidden="true"
                                ></i>
                              </td>
                            </tr>
                            <tr>
                              <td>شبكه-الأخبار.الجزائر</td>
                              <td>
                                <i
                                  className="bi bi-x-lg wrong"
                                  aria-hidden="true"
                                ></i>
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </motion.div>
  );
};

export default Rules;
